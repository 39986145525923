import React from "react"

const Home = React.lazy(() => import('./Pages/Home/index'));


//Organisator Starts

const Tours = React.lazy(() => import("./Pages/Tours/Tours"))

//Organisator Ends

//Accommodation Starts

const Facilities = React.lazy(() => import("./Pages/Accommodation/Facility/Facilities"))
const Facility = React.lazy(() => import("./Pages/Accommodation/Facility/Facility"))
const Rooms = React.lazy(() => import("./Pages/Accommodation/Room/Rooms"))
const Room = React.lazy(() => import("./Pages/Accommodation/Room/Room"))
const Guests = React.lazy(() => import("./Pages/Accommodation/Guests/Guests"))
const Rating = React.lazy(() => import("./Pages/Accommodation/Rating/Rating"))
const Calendar = React.lazy(() => import("./Pages/Accommodation/Calendar/Calendar"))
const Earnings = React.lazy(() => import("./Pages/Earnings/Earnings"))
const MyCompany = React.lazy(() => import('./Pages/Company/MyCompany'))

//Accomodation Ends

const routes = [
    { path: '/', exact: true, name: 'trcontact', },
    { path: '/dashboard', name: 'Home', component: Home },

    //Organisator Starts

    { path: '/o-tours', name: 'Turlar', component: Tours },

    //Organisator Ends

    //Accommodation Starts

    { path: '/a-facilities', name: 'Tesisler', component: Facilities },
    { path: '/facility-detail', name: 'Tesis Detay', component: Facility },
    { path: '/a-rooms', name: 'Odalar ve Mülkler', component: Rooms },
    { path: '/room-detail', name: 'Odalar Detay', component: Room },
    { path: '/a-guests', name: 'Odalar ve Mülkler', component: Guests },
    { path: '/a-ratings', name: 'Puan ve Yorumlar', component: Rating },
    { path: '/a-calendar', name: 'Takvim', component: Calendar },
    { path: '/earnings', name: 'Kazançlar', component: Earnings },
    { path: '/company', name: 'Firma Detay', component: MyCompany },

    //Accomodation Ends


]

export default routes;