const home = [
  {
    icon: "pe-7s-home",
    label: 'Anasayfa',
    to: '#/dashboard',
  },
  {
    icon: "pe-7s-home",
    label: 'Firma Bilgileri',
    to: '#/company',
  },
  {
    icon: "pe-7s-plugin",
    label: 'Kazançlar',
    to: '#/earnings',
  },
  /*{
    icon: "pe-7s-plugin",
    label: 'Countries',
    to: '#/countries',
  },*/
]
const organizator = [
  {
    icon: "lnr-map",
    label: 'Turlar',
    to: '#/o-tours',
  },
  {
    icon: "lnr-apartment",
    label: 'Tesisler',
    to: '#/o-facilities',
  },
  {
    icon: "pe-7s-door-lock",
    label: 'Odalar & Mülkler',
    to: '#/o-rooms',
  },
  {
    icon: "lnr-list",
    label: 'Organizatör',
    content: [
      {
        label: 'Firma',
        to: '#/company-categories'
      },
      {
        label: 'Ulaşım',
        to: '#/transport-categories'
      },
      {
        label: 'Accomodation',
        to: '#/accommodation-categories'
      },
      {
        label: 'Event',
        to: '#/event-categories'
      },
      {
        label: 'Food',
        to: '#/food-categories'
      },
      {
        label: 'Service',
        to: '#/service-categories'
      },
      {
        label: 'Destination',
        to: '#/destination-categories'
      },
    ]
  },
]

const catering = [
  {
    icon: "lnr-list",
    label: 'Catering',
    content: [
      {
        label: 'Company',
        to: '#/company-categories'
      },
      {
        label: 'Transport',
        to: '#/transport-categories'
      },
      {
        label: 'Accomodation',
        to: '#/accommodation-categories'
      },
      {
        label: 'Event',
        to: '#/event-categories'
      },
      {
        label: 'Food',
        to: '#/food-categories'
      },
      {
        label: 'Service',
        to: '#/service-categories'
      },
      {
        label: 'Destination',
        to: '#/destination-categories'
      },
    ]
  },
]

const accommodation = [
  {
    icon: "lnr-calendar-full",
    label: 'Rezervasyonlar',
    to: '#/a-calendar',
  },
  {
    icon: "lnr-apartment",
    label: 'Tesisler',
    to: '#/a-facilities',
  },
  {
    icon: "pe-7s-door-lock",
    label: 'Odalar & Mülkler',
    to: '#/a-rooms',
  },
  {
    icon: "lnr-star-half",
    label: 'Yorumlar',
    to: '#/a-ratings',
  },
]

const transport = [
  {
    icon: "lnr-list",
    label: 'Ulasim',
    content: [
      {
        label: 'Company',
        to: '#/company-categories'
      },
      {
        label: 'Transport',
        to: '#/transport-categories'
      },
      {
        label: 'Accomodation',
        to: '#/accommodation-categories'
      },
      {
        label: 'Event',
        to: '#/event-categories'
      },
      {
        label: 'Food',
        to: '#/food-categories'
      },
      {
        label: 'Service',
        to: '#/service-categories'
      },
      {
        label: 'Destination',
        to: '#/destination-categories'
      },
    ]
  },
]

export default { home, organizator, catering, accommodation, transport };


