import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import _nav from "./_myNav";
import { useAtom } from "jotai";
import { companyAtom } from "../../_atoms/company";

const Nav = props => {
  const [company] = useAtom(companyAtom)
  const toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  const categories = (company && company._id) ? company.categories.map(c => c._id) : []

  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Anasayfa</h5>
      <MetisMenu content={_nav.home} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
      {categories.indexOf("6442a9bd882ee8ea59d34ca1") > -1 &&
        <>
          <h5 className="app-sidebar__heading">Organizatör</h5>
          <MetisMenu content={_nav.organizator} onSelected={toggleMobileSidebar} activeLinkFromLocation
            className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
        </>
      }
      {categories.indexOf("6442af4be0d9f7ebd17e40a7") > -1 &&
        <>
          <h5 className="app-sidebar__heading">Catering</h5>
          <MetisMenu content={_nav.catering} onSelected={toggleMobileSidebar} activeLinkFromLocation
            className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
        </>
      }
      {categories.indexOf("6448e0b550a3982d6e089a5e") > -1 &&
        <>
          <h5 className="app-sidebar__heading">Konaklama</h5>
          <MetisMenu content={_nav.accommodation} onSelected={toggleMobileSidebar} activeLinkFromLocation
            className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
        </>
      }
      {categories.indexOf("6448e0ff50a3982d6e089a62") > -1 &&
        <>
          <h5 className="app-sidebar__heading">Taşıma</h5>
          <MetisMenu content={_nav.transport} onSelected={toggleMobileSidebar} activeLinkFromLocation
            className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
        </>
      }
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
