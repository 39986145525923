import React, { Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Loader from "react-loaders";
import { ToastContainer } from "react-toastify";
import { Fade, Col, Row, Card } from "reactstrap";
import routes from "../../myRoutes"
import Layout from "../Layout"
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const loading = (
    <div className="loader-container">
        <div className="loader-container-inner">
            <div className="text-center">
                <Loader type="ball-grid-cy" />
            </div>
        </div>
    </div>
)

const AppMain = () => {
    return (
        <Layout>
            <Suspense fallback={loading}>
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component && (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <Fade>
                                        <Row>
                                            <Col xs={12}>
                                                <TransitionGroup>
                                                    <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                                                        timeout={1500} enter={false} exit={false}>
                                                        <div>
                                                            <route.component {...props} />
                                                        </div>
                                                    </CSSTransition>
                                                </TransitionGroup>
                                            </Col>
                                        </Row>
                                    </Fade>
                                )}
                            />
                        )
                    })}
                    <Redirect from="/" to="/dashboard" />
                </Switch>
                <ToastContainer />
            </Suspense>
        </Layout>
    )
}

export default React.memo(AppMain)
